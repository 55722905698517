export const getJson = data => ({
    id: data.id,
    key: data.key,
    value: data.value,
    type: data.type,
    file: data.file,
})

export const getArray = data => data.map(setting => getJson(setting))

export const setData = data => ({
    settings: data.settings.map(x => ({ key: x.key, value: x.value })),
    file_batch_id: data.file_batch_id,
})
