import axios from 'axios'
import url from '../url'
import {
    setData, getJson, getArray,
} from '../resources/settingResource'

export default class SettingRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle
    }

    async index() {
        const response = await axios.get(url('indexSetting'))
        if (response.status === 200) {
            return getArray(response.data.data)
        }
        return {}
    }

    async store(data) {
        const json = setData(data)
        const response = await axios.post(url('storeSetting'), json)
        if (response.status === 200) {
            return getJson(response.data)
        }
        return null
    }
}
