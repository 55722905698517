<template>
  <component :is="form === undefined ? 'div' : 'b-card'">
    <template>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
          <b-row v-for="(setting, index) in settings" :key="setting.id">
            <b-col cols="12" sm="6" md="6">
              <b-form-group :label="setting.key" :label-for="setting.key">
                <b-form-file
                  v-if="setting.type == 'file'"
                  :id="setting.key"
                  v-model="fileForm.file"
                  :state="Boolean(fileForm.file)"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                />

                <b-form-input
                  v-if="setting.type == 'input'"
                  :id="setting.key"
                  v-model="form.settings[index].value"
                  trim
                />
                <b-form-checkbox
                  v-if="setting.type == 'switch'"
                  :id="setting.key"
                  v-model="form.settings[index].value"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
            <!-- Form Actions -->
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mr-2"
                  type="submit"
                  :disabled="loading"
                >
                  <span v-show="!loading"> Save </span>
                  <b-spinner v-show="loading" label="Loading..." />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </component>
</template>

<script>
import {
  BAlert,
  BButton,
  BSpinner,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  BTab,
  BTabs,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { onUnmounted, ref, onMounted } from "@vue/composition-api";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
import SettingRepository from "@/abstraction/repository/settingRepository";
import { makeid } from "@/utils/math";
// eslint-disable-next-line import/no-cycle
import useUsersList from "../user/useUsersList";
import userStoreModule from "../user/userStoreModule";
import FileRepository from "@/abstraction/repository/fileRepository";
const staticImageRepository = new SettingRepository();
import router from '@/router'
const RANDOM_TOKEN = makeid(50);
export default {
  components: {
    BTab,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BForm,
    BFormCheckboxGroup,
    BSpinner,
    BFormTextarea,
    BFormFile,
    BImg,
    BMedia,
    BTabs,
    BCard,
    BAlert,
    BRow,
    BCol,
    vSelect,
    BButton,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormCheckbox,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    required,
    types: ["header"],
    loading: false,
    isLoading: false,
    image: null,
  }),
  setup() {
    const blankForm = {
      settings: [],
      file_batch_id: RANDOM_TOKEN,
    };
    const fileForm = ref({
      batch_id: RANDOM_TOKEN,
      collection_name: "header_image",
      model_name: "setting",
    });
    const roleOptions = [
      {
        label: "Admin",
        value: "admin",
      },
      {
        label: "Author",
        value: "author",
      },
      {
        label: "Editor",
        value: "editor",
      },
      {
        label: "Maintainer",
        value: "maintainer",
      },
      {
        label: "Subscriber",
        value: "subscriber",
      },
    ];

    const planOptions = [
      {
        label: "Basic",
        value: "basic",
      },
      {
        label: "Company",
        value: "company",
      },
      {
        label: "Enterprise",
        value: "enterprise",
      },
      {
        label: "Team",
        value: "team",
      },
    ];
    const form = ref(blankForm);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    const { refetchData } = useUsersList();
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    }
    const settings = ref([]);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      }
    });
    onMounted(async () => {
      settings.value = await staticImageRepository.index();
      form.value.settings = settings.value;
    });
    const resetform = () => {
      form.value = JSON.parse(JSON.stringify(blankForm));
    };
    const onSubmit = async () => {
      try {
        const fileRepository = new FileRepository();
        if (fileForm.value.file) {
          await fileRepository.store(fileForm.value);
        }
        await staticImageRepository.store(form.value);
        console.log(fileForm.value, form.value);
        await router.push({ name: "dashboard" });
        return true;
      } catch (e) {
        console.log(e);
        return e;
      }
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetform);
    return {
      form,
      fileForm,
      refetchData,
      onSubmit,
      settings,
      refFormObserver,
      getValidationState,
      resetForm,
      avatarText,
      roleOptions,
      planOptions,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
